import React from "react";
import about from "../image/about3.png";
import { Link } from "react-router-dom";
import Icon from "../jsons/Icon";

function AboutPage() {
  return (
    <>
      <div class="text-center p-8">
        <div class="flex flex-wrap items-center text-left">
          <div class="lg:pr-8 lg:pt-4">
            <div class="lg:max-w-lg">
              <h2 class="text-base font-semibold leading-7 brand-color">
                About Us
              </h2>
              <p class="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Digital Dreams: Bringing Your Vision to Life in the Digital
                World
              </p>
              <dl class="mt-5 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      class="absolute left-1 top-1 h-5 w-5 brand-color"
                    >
                      <path d="M3.196 12.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 12.87z"></path>
                      <path d="M3.196 8.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 8.87z"></path>
                      <path d="M10.38 1.103a.75.75 0 00-.76 0l-7.25 4.25a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.76 0l7.25-4.25a.75.75 0 000-1.294l-7.25-4.25z"></path>
                    </svg>
                  </dt>
                  <dd class="inline">
                    Established on December 21, 2022, in Noida, India, CodeArtha
                    Technologies Pvt Ltd offers innovative digital solutions.
                    Our expert team delivers top-quality services, from custom
                    software development to IT consulting, empowering businesses
                    to thrive in today's competitive landscape. With a focus on
                    excellence and client satisfaction, we cater to diverse
                    industry needs, providing tailored solutions to drive
                    success.
                  </dd>
                </div>
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      class="absolute left-1 top-1 h-5 w-5 brand-color"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </dt>
                  <dd class="inline">
                    At CodeArtha, we leverage our expertise and cutting-edge
                    technologies to deliver efficient and effective solutions
                    that meet the evolving demands of the digital age, ensuring
                    our clients stay ahead of the curve.
                  </dd>
                </div>
              </dl>
            </div>
            <div class="mt-10 flex items-center gap-x-6">
              <Link
                to="/contact"
                class="rounded-md bg-brand-color px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Schedule a Call
              </Link>
              <Link
                to="/"
                class="text-sm font-semibold leading-6 text-gray-700 hover:bg-brand-color"
              >
                Back to Home
                <span aria-hidden="true" className="text-xl ml-1">
                  →
                </span>
              </Link>
            </div>
          </div>
          <div class="w-full md:w-3/5 lg:w-1/2 px-4">
            <img src={about} alt="gem" class="inline-block rounded" />
          </div>
        </div>
      </div>

      <section class="bg-gray-50 py-12 sm:py-16 lg:py-20 xl:py-24">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="text-center">
            <p class="text-sm font-bold uppercase tracking-widest text-gray-700">
              How It Works
            </p>
            <h2 class=" text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl">
              Crafting Tailored Strategies for Your Success
            </h2>
          </div>
          <ul class="mx-auto mt-8 grid max-w-md grid-cols-1 gap-10 sm:mt-10 lg:mt-10 lg:max-w-5xl lg:grid-cols-5 cursor-pointer">
            <li class="flex-start group relative flex lg:flex-col">
              <span
                class="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-300 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                aria-hidden="true"
              ></span>
              <div class="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border brand-color border-brand-color bg-gray-50 transition-all duration-200 group-hover:border-[#60A5FA] group-hover:bg-[#60A5FA]">
                <Icon
                  name="search"
                  size={20}
                  className="h-5 w-5 brand-color group-hover:text-white"
                />
              </div>
              <div class="ml-6 lg:ml-0 lg:mt-5">
                <h3 class="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                  Research
                </h3>
                <h4 class="mt-2 text-base text-gray-700">
                  We thrive on data! Analyzing insights, we understand your
                  goals and business needs
                </h4>
              </div>
            </li>
            <li class="flex-start group relative flex lg:flex-col">
              <span
                class="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-300 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                aria-hidden="true"
              ></span>
              <div class="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border brand-color border-brand-color bg-gray-50 transition-all duration-200 group-hover:border-[#60A5FA] group-hover:bg-[#60A5FA]">
                <Icon
                  name="strategy"
                  size={20}
                  className="h-5 w-5 brand-color group-hover:text-white"
                />
              </div>
              <div class="ml-6 lg:ml-0 lg:mt-5">
                <h3 class="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                  Strategey
                </h3>
                <h4 class="mt-2 text-base text-gray-700">
                  Success begins with thorough research and strategic planning,
                  paving the path for achievements.
                </h4>
              </div>
            </li>
            <li class="flex-start group relative flex lg:flex-col">
              <span
                class="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-300 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                aria-hidden="true"
              ></span>
              <div class="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border brand-color border-brand-color bg-gray-50 transition-all duration-200 group-hover:border-[#60A5FA] group-hover:bg-[#60A5FA]">
                <Icon
                  name="desgin"
                  size={20}
                  className="h-5 w-5 brand-color group-hover:text-white"
                />
              </div>
              <div class="ml-6 lg:ml-0 lg:mt-5">
                <h3 class="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                  Design
                </h3>
                <h4 class="mt-2 text-base text-gray-700">
                  Crafting beautiful, intuitive interfaces tailored to your
                  business needs is our specialty.
                </h4>
              </div>
            </li>
            <li class="flex-start group relative flex lg:flex-col">
              <span
                class="absolute left-[18px] top-14 h-[calc(100%_-_32px)] w-px bg-gray-300 lg:right-0 lg:left-auto lg:top-[18px] lg:h-px lg:w-[calc(100%_-_72px)]"
                aria-hidden="true"
              ></span>
              <div class="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border brand-color border-brand-color bg-gray-50 transition-all duration-200 group-hover:border-[#60A5FA] group-hover:bg-[#60A5FA]">
                <Icon
                  name="code"
                  size={20}
                  className="h-5 w-5 brand-color group-hover:text-white"
                />
              </div>
              <div class="ml-6 lg:ml-0 lg:mt-5">
                <h3 class="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                  Development
                </h3>
                <h4 class="mt-2 text-base text-gray-700">
                  We bring your vision to life, building robust solutions with
                  elegance and functionality.
                </h4>
              </div>
            </li>
            <li class="flex-start group relative flex lg:flex-col">
              <div class="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-xl border brand-color border-brand-color bg-gray-50 transition-all duration-200 group-hover:border-[#60A5FA] group-hover:bg-[#60A5FA]">
                <Icon
                  name="cloud"
                  size={20}
                  className="h-5 w-5 brand-color group-hover:text-white"
                />
              </div>
              <div class="ml-6 lg:ml-0 lg:mt-5">
                <h3 class="text-xl font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                  Deployment
                </h3>
                <h4 class="mt-2 text-base text-gray-700">
                  Seamless implementation ensures solutions exceed expectations
                  with ease of use and maintenance.
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="bg-gray-70 py-16 mx-4">
        <div class="max-w-5xl mx-auto ">
          <div class=" grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-brand-color text-white">
                <Icon name="home" size="25" />
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-medium text-gray-900">Mission</h3>
                <p class="mt-2 text-base text-gray-500">
                  We aim to revolutionize the digital landscape through
                  innovative solutions and collaborative partnerships, making a
                  positive impact on businesses and society.
                </p>
              </div>
            </div>
            <div>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-brand-color text-white">
                <Icon name="value" size="25" />
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-medium text-gray-900">Values</h3>
                <p class="mt-2 text-base text-gray-500">
                  At the core of our operations lie honesty, integrity, and
                  respect for all individuals. We uphold these values in every
                  interaction, fostering trust and mutual respect.
                </p>
              </div>
            </div>
            <div>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-brand-color text-white">
                <Icon name="vision" size="25" />
              </div>
              <div class="mt-5">
                <h3 class="text-lg font-medium text-gray-900">Vision</h3>
                <p class="mt-2 text-base text-gray-500">
                  Our vision is to leverage technology as a force for good,
                  addressing pressing global challenges and enhancing the
                  quality of life for people worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutPage;
