import React from "react";
import Icon from "../jsons/Icon";

const Faqs = () => {
  const data = [
    {
      id: 1,
      title: "What services does your company provide?",
      desc: "We provide a range of services including web development, app development, e-commerce solutions, UI/UX design, and cloud operations.",
    },
    {
      id: 2,
      title: "What industries do you cater to?",
      desc: "We cater to various industries including but not limited to e-commerce, healthcare, finance, education, and technology.",
    },
    {
      id: 3,
      title: "How do you ensure the security of the applications developed?",
      desc: "Security is a top priority for us. We follow industry best practices and employ robust security measures such as encryption, secure coding practices, and regular security audits to ensure the safety of your applications.",
    },
    {
      id: 4,
      title: "Can you handle both front-end and back-end development?",
      desc: "Yes, we have expertise in both front-end and back-end development. Whether it's creating a user-friendly interface or building a scalable and efficient server-side architecture, we've got you covered.",
    },
    {
      id: 5,
      title:
        "Do you provide ongoing maintenance and support for the applications developed?",
      desc: "Absolutely! We offer ongoing maintenance and support services to ensure that your applications remain up-to-date, secure, and perform optimally even after deployment.",
    },
    {
      id: 6,
      title: "Can you help migrate existing applications to the cloud?",
      desc: "Yes, we specialize in cloud operations and can assist you in migrating existing applications to the cloud. We'll ensure a seamless transition while maximizing the benefits of cloud computing such as scalability, flexibility, and cost-efficiency.",
    },
    {
      id: 7,
      title: "How do we get started with your services?",
      desc: "Getting started is easy! Simply reach out to us via our contact page or schedule a consultation. We'll discuss your requirements, provide tailored recommendations, and outline the next steps to kickstart your project.",
    },
    {
      id: 8,
      title: "Do you provide post-launch support and maintenance?",
      desc: "Yes, we offer post-launch support and maintenance services to address any issues, implement updates, and ensure the continued smooth operation of your applications. Our support team is available to assist you whenever needed.",
    },
    {
      id: 9,
      title: "What is your pricing model?",
      desc: "Our pricing model is flexible and depends on factors such as project scope, complexity, and timeline. We offer competitive pricing and transparent billing practices, ensuring that you receive the best value for your investment.",
    },
    {
      id: 10,
      title: "How do you ensure timely delivery of projects?",
      desc: "Timely delivery is a key focus for us. We follow agile development methodologies, set clear project timelines, and maintain open communication throughout the development process to ensure timely delivery of projects.",
    },
    {
      id: 11,
      title: "Can you provide references or case studies of past projects?",
      desc: "Absolutely! We'd be happy to provide references or share case studies of past projects that demonstrate our capabilities and track record of success. Feel free to reach out to us for more information.",
    },
  ];
  return (
    <div>
      {" "}
      <div className="w-full px-2 sm:px-10 pb-10">
        <div class="relative  px-6 pt-5 pb-8 mt-8 ring-1 ring-gray-900/5 sm:px-10">
          <h3 class="pb-2 brand-color text-xl font-semibold text-left">
            FAQ's
          </h3>
          <div class="mx-auto  max-w-full sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl">
            <div class="mx-auto grid w-full divide-y ">
              {data.map((data) => (
                <div key={data.id} class="py-5">
                  <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span>
                        <span className="text-neutral-300">{data.id}.</span>{" "}
                        {data.title}
                      </span>
                      <span class="transition group-open:rotate-180">
                        <Icon name="arrowDown" className="brand-color" />
                      </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                      {data.desc}
                    </p>
                  </details>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
