import React from "react";
import { FaLaptop, FaChartLine, FaUncharted } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { MdOutlineAppSettingsAlt } from "react-icons/md";
import { Link } from "react-router-dom";

function Service() {
  const services = [
    {
      id: 1,
      title: "Web Development",
      desc: "A web development agency specializes in building, designing, deploying, updating, and maintaining websites and web applications.",
      icons: (
        <>
          <FaLaptopCode size={40} />
        </>
      ),
    },
    {
      id: 2,
      title: "App Development",
      desc: "Mobile application development is the set of processes and procedures involved in writing software for small, wireless computing devices.",
      icons: (
        <>
          <MdOutlineAppSettingsAlt size={40} />
        </>
      ),
    },
    {
      id: 3,
      title: "UI/UX Desgin",
      desc: "UI/UX creates a connection between user and service provider, improve customer satisfaction, improve user interaction, as well as boost your business grow and enhance.",
      icons: (
        <>
          <FaLaptop size={40} />
        </>
      ),
    },
    {
      id: 4,
      title: "E-Commerce (Shopify partner)",
      desc: "E-commerce is the buying and selling of goods or services via the internet, and the transfer of money and data to complete the sales.",
      icons: (
        <>
          <FaUncharted size={40} />
        </>
      ),
    },
    {
      id: 5,
      title: "Cloud Operation",
      desc: "DevOps integrates and automates the work of software development and IT operations as a means for improving and shortening the systems development life cycle.",
      icons: (
        <>
          <FaChartLine size={40} />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="bg-gray-200 px-2 py-10">
        <div id="features" className="mx-auto max-w-6xl">
          <div className="flex items-center justify-between gap-8 ">
            <div className="flex items-center gap-12">
              <h2 className="text-2xl font-bold text-gray-800 lg:text-3xl dark:text-white">
                Our Services
              </h2>
            </div>

            <Link
              to="/"
              className="inline-block rounded-lg px-2 py-2 text-center text-sm font-semibold text-gray-500 md:py-3 md:text-base transition-all duration-200 hover:underline"
            >
              View Services
            </Link>
          </div>
          <p className="hidden max-w-screen-sm text-gray-500 dark:text-gray-300 md:block">
            We deliver a comprehensive range of cutting-edge digital solutions,
            including custom software development, cloud computing, data
            analytics, mobile app development, and digital transformation
            services to empower your business in the digital age.
          </p>
          <ul className="mt-6 grid grid-cols-1 gap-6 text-center md:grid-cols-3 cursor-pointer">
            {services.map((data) => (
              <Link to="/service">
                <li className="rounded-xl bg-white px-6 py-8 shadow-sm flex flex-col items-center justify-center">
                  <span className="text-center mb-0 brand-color">
                    {data.icons}
                  </span>
                  <h3 className="my-1 font-display font-medium">
                    {data.title}
                  </h3>
                  <p className=" text-sm leading-6 text-neutral-500">
                    {data.desc}
                  </p>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Service;
