import React from "react";
import Faqt from "./home/Faqt";
import Service from "./home/Service";
import Information from "./home/Information";
import Intro from "./home/Intro";
import News from "./home/News";
import DigitalAds from "./home/DigitalAds";
import About from "./home/About";
import Header from "./Header";

export default function Home() {
  return (
    <>
      <Intro />
      <DigitalAds />
      <Service />
      <About />
      <Information />
      {/* <News /> */}
      <Faqt />
    </>
  );
}
