import React from "react";
import tech1 from "../image/tech1.jpg";
import tech2 from "../image/tech2.jpg";
import tech3 from "../image/tech3.jpg";

const DigitalAds = () => {
  const data = [
    {
      id: 1,
      title: "Digital at Heart",
      desc: " We reimagine the possibilities for digital transformation from invovation to growth.",
      src: tech1,
    },
    {
      id: 2,
      title: "Digital at Thinking",
      desc: "Scalability, managebility robustness, and efficiency is our top prioriy , always.",
      src: tech2,
    },
    {
      id: 3,
      title: "Digital at Approch ",
      desc: "We plan everything to minute details ensuring high-quality delivereies to our client , every time.",
      src: tech3,
    },
  ];
  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-10 lg:py-14 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
          {data.map((data, index) => (
            <a
              className="group relative block  overflow-hidden "
              href="#"
              key={index}
            >
              <div className="flex-shrink-0 relative  overflow-hidden h-[350px] before:absolute before:inset-0 before:z-[1] before:size-full before:bg-gradient-to-t before:from-gray-900/70">
                <img
                  className="size-full absolute top-0 start-0 object-cover"
                  src={data.src}
                  alt={data.imageAlt}
                />
              </div>

              <div className="absolute bottom-0 inset-x-0 z-10 p-4 sm:p-6">
                <h3 className="text-lg sm:text-3xl font-semibold text-white group-hover:text-white/80">
                  {data.title}
                </h3>
                <p className="mt-2 text-white/80">{data.desc}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default DigitalAds;
