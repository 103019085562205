import React from "react";
import { Routes, Route } from "react-router-dom";
import { ContactPage } from "./component/contact/ContactPage";
import Home from "./component/Home";
import Navbar from "./component/Navbar";
import ServicePage from "./component/service/ServicePage";
import Footer from "./component/Footer";
import CareerPage from "./component/career/CareerPage";
import AboutPage from "./component/about/AboutPage";
import AddBlog from "./component/admin/AddBlog";
import BlogPage from "./component/Blogs/BlogPage";
import Blogs from "./component/Blogs/Blogs";

function App() {
  return (
    <>
      <Navbar />
      {/* <Header /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/career" element={<CareerPage />} />
        {/* <Route path="/admin/add-blog" element={<AddBlog />} /> */}
        {/* <Route path="/blog" element={<BlogPage />} /> */}
        {/* <Route path="/blogs" element={<Blogs />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
