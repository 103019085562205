import React from "react";
import bgImage from "../image/bg_banner1.jpg";

const Intro = () => {
  return (
    <div>
      <div
        className="h-screen w-full"
        style={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}
      >
        <div className="flex items-center justify-center sm:justify-start h-full mx-10">
          <div className="w-full sm:w-1/2">
            <div className="whitespace-normal">
              <p className="text-md font-bold brand-color">
                WE CRAFT DIGITAL EXPERIENCE DEVELOP YOUR IDEAS INTO REALITY
              </p>
              <h2 className="text-4xl sm:text-7xl font-bold text-white">
                Web & App Development
              </h2>
            </div>

            <div className="flex items-center py-5">
              <button className="group text-white w-fit px-6 py-3 mx-2 flex items-center rounded-md bg-gradient-to-r from-blue-400 to-blue-600 cursor-pointer">
                Get Started
              </button>
              <button className="group text-white w-fit px-6 py-3 mx-2 flex items-center rounded-md border border-white cursor-pointer">
                Our Work
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
