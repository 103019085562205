import React from "react";
import {
  FaHome,
  FaRegEnvelope,
  FaPhone,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaFacebookF,
  FaLinkedin,
  FaYoutube,
  FaLinkedinIn,
  FaArrowDown,
  FaArrowLeft,
} from "react-icons/fa";
import { MdAddCall } from "react-icons/md";
import { Ri24HoursFill } from "react-icons/ri";
import { MdMailOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import {
  MdOutlineWeb,
  MdOpenInBrowser,
  MdOutlineSecurity,
  MdOutlineVerified,
  MdOutlineMessage,
} from "react-icons/md";
import {
  FaSearch,
  FaRegLightbulb,
  FaPencilRuler,
  FaLaptopCode,
  FaCloudUploadAlt,
  FaGraduationCap,
  FaArrowRight,
  FaAngleDown,
  FaDesktop,
  FaUsers,
} from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";

import { CgWebsite } from "react-icons/cg";
import { FaCloudscale } from "react-icons/fa";
import { FaSearchLocation, FaUserCheck } from "react-icons/fa";
import {
  MdOutlineRealEstateAgent,
  MdOutlineVisibility,
  MdOutlineSupportAgent,
} from "react-icons/md";

const Icon = ({ name, size, color, className }) => {
  const iconComponents = {
    speaker: HiOutlineSpeakerphone,
    home: FaHome,
    envelope: FaRegEnvelope,
    phone: FaPhone,
    twitter: FaTwitter,
    facebook1: FaFacebook,
    facebook2: FaFacebookF,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    linkedin2: FaLinkedinIn,
    youtube: FaYoutube,
    mail: MdMailOutline,
    phone: MdAddCall,
    location: IoLocationOutline,
    hour: Ri24HoursFill,
    web1: MdOutlineWeb,
    web2: CgWebsite,
    web3: FaCloudscale,
    security: MdOutlineSecurity,
    seo: FaSearchLocation,
    browser: MdOpenInBrowser,
    downArrowKey: FaArrowDown,
    estabilesd: MdOutlineRealEstateAgent,
    search: FaSearch,
    strategy: FaRegLightbulb,
    desgin: FaPencilRuler,
    code: FaLaptopCode,
    cloud: FaCloudUploadAlt,
    value: FaGraduationCap,
    vision: MdOutlineVisibility,
    arrowRight: FaArrowRight,
    arrowDown: FaAngleDown,
    arrowLeft: FaArrowLeft,
    support: MdOutlineSupportAgent,
    laptop: FaDesktop,
    userCheck: FaUserCheck,
    multipleuser: FaUsers,
    verified: MdOutlineVerified,
    message: MdOutlineMessage,
  };

  const IconComponent = iconComponents[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <span className={className}>
      <IconComponent size={size} color={color} />
    </span>
  );
};

export default Icon;
