import emailjs from "@emailjs/browser";
import Icon from "../jsons/Icon";
import Faqs from "./Faqs";
import React, { useRef, useState } from "react";

export const ContactPage = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    const updatedErrors = {};

    if (!formData.name || !formData.name.trim()) {
      updatedErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.name || !formData.email.trim()) {
      updatedErrors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      updatedErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      updatedErrors.mobileNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(formData.mobileNumber)) {
      updatedErrors.mobileNumber = "Invalid mobile number";
      isValid = false;
    }
    if (!formData.subject || !formData.subject.trim()) {
      updatedErrors.subject = "Suject is required";
      isValid = false;
    }

    setErrors(updatedErrors);
    if (isValid) {
      emailjs
        .sendForm("service_ycrca4i", "template_v9vzmo8", form.current, {
          publicKey: "x3QW0UzzsYAEXZgNd",
        })
        .then(() => {
          console.log("SUCCESS!");
          setFormData({
            name: "",
            email: "",
            mobileNumber: "",
            subject: "",
            message: "",
          });
          setSuccessMessage("Message sent successfully!");
        })
        .catch((error) => {
          console.log("FAILED...", error.text);
          setErrorMessage("Failed to send message. Please try again later.");
        });
    }
  };

  console.log(errorMessage);

  return (
    <div>
      <div className=" px-2 py-10 bg-gray-100">
        <div class="mx-auto max-w-6xl">
          <div class="grid md:grid-cols-2">
            <div class="h-full ">
              <h2 class="text-2xl font-bold dark:text-white">
                Ready to Get Started?
              </h2>
              <p class="mb-12 text-md text-neutral-500 ">
                Have specific application or technology integration
                requirements? Get in touch with us. We would be glad to help
                you.
              </p>
              <ul class="mb-6 md:mb-0">
                <li class="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brand-color">
                    <Icon name="location" size={20} color="white" />
                  </div>
                  <div class="ml-4 mb-4">
                    <h3 class=" text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Our Address
                    </h3>
                    <p class="text-gray-600 dark:text-slate-400">
                      We can only communicate online.
                    </p>
                  </div>
                </li>
                <li class="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brand-color">
                    <Icon name="phone" size={20} color="white" />
                  </div>
                  <div class="ml-4 mb-4">
                    <h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Contact
                    </h3>
                    <p class="text-gray-600 dark:text-slate-400">
                      Mobile: +91 7351500899
                    </p>
                    <p class="text-gray-600 dark:text-slate-400">
                      Mail: codearthamail@gmail.com
                    </p>
                  </div>
                </li>
                <li class="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-brand-color">
                    <Icon name="hour" size={20} color="white" />
                  </div>
                  <div class="ml-4 mb-4">
                    <h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Working hours
                    </h3>
                    <p class="text-gray-600 dark:text-slate-400">
                      Monday - Friday: 08:00 - 17:00
                    </p>
                    <p class="text-gray-600 dark:text-slate-400">
                      Saturday &amp; Sunday: 08:00 - 12:00
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card h-fit max-w-6xl p-4">
              <form ref={form} onSubmit={handleSubmit} id="form">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] brand-color"
                    htmlFor="exampleInput90"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`px-2 py-2 border w-full outline-none rounded-md ${
                      errors.name && "border-red-500"
                    }`}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                  )}
                </div>
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] brand-color"
                    htmlFor="exampleInput90"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Mobil Number"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className={`px-2 py-2 border w-full outline-none rounded-md ${
                      errors.mobileNumber && "border-red-500"
                    }`}
                  />
                  {errors.mobileNumber && (
                    <p className="text-red-500 text-sm">
                      {errors.mobileNumber}
                    </p>
                  )}
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] brand-color"
                    htmlFor="exampleInput90"
                  >
                    Email Id
                  </label>
                  <input
                    type="email"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="email"
                    name="email"
                    placeholder="Email Id"
                    value={formData.email}
                    onChange={handleChange}
                    className={`px-2 py-2 border w-full outline-none rounded-md ${
                      errors.email && "border-red-500"
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] brand-color"
                    htmlFor="exampleInput90"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className={`px-2 py-2 border w-full outline-none rounded-md ${
                      errors.subject && "border-red-500"
                    }`}
                  />
                  {errors.subject && (
                    <p className="text-red-500 text-sm">{errors.subject}</p>
                  )}
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] brand-color"
                    htmlFor="exampleInput90"
                  >
                    Message
                  </label>
                  <textarea
                    class="px-2 py-2 border rounded-[5px] w-full outline-none"
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    className={`px-2 py-2 border w-full outline-none rounded-md ${
                      errors.message && "border-red-500"
                    }`}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  class="mb-6 inline-block w-full rounded bg-brand-color px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover-bg-brand-color"
                >
                  Send
                </button>
                {successMessage && (
                  <p className="text-teal-500">{successMessage}</p>
                )}
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Faqs />
    </div>
  );
};
