import {
  GET_BLOG_BY_ID_FAILURE,
  GET_BLOG_BY_ID_REQUEST,
  GET_BLOG_BY_ID_SUCCESS,
  WRITE_BLOG_FAILURE,
  WRITE_BLOG_REQUEST,
  WRITE_BLOG_SUCCESS,
} from "./actionType";

const initState = {
  writeBlog: null,
  error: null,
  loading: false,
  blogs: [],
};

export const blogReducer = (state = initState, action) => {
  switch (action.type) {
    case WRITE_BLOG_REQUEST:
    case GET_BLOG_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WRITE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        writeBlog: action.payload,
        error: null,
      };
    case WRITE_BLOG_FAILURE:
    case GET_BLOG_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_BLOG_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        blogs: action.payload,
        error: null,
      };

    default:
      return state;
  }
};
