import React from "react";
import Icon from "../jsons/Icon";

const Faqt = () => {
  const data = [
    {
      id: 1,
      title:
        "Do you provide ongoing maintenance and support for the applications developed?",
      desc: "Absolutely! We offer ongoing maintenance and support services to ensure that your applications remain up-to-date, secure, and perform optimally even after deployment.",
    },
    {
      id: 2,
      title: "Can you help migrate existing applications to the cloud?",
      desc: "Yes, we specialize in cloud operations and can assist you in migrating existing applications to the cloud. We'll ensure a seamless transition while maximizing the benefits of cloud computing such as scalability, flexibility, and cost-efficiency.",
    },
    {
      id: 3,
      title: "How do we get started with your services?",
      desc: "Getting started is easy! Simply reach out to us via our contact page or schedule a consultation. We'll discuss your requirements, provide tailored recommendations, and outline the next steps to kickstart your project.",
    },
    {
      id: 4,
      title: "What is your pricing model?",
      desc: "Our pricing model is flexible and depends on factors such as project scope, complexity, and timeline. We offer competitive pricing and transparent billing practices, ensuring that you receive the best value for your investment.",
    },
  ];
  return (
    <>
      <div className="w-full px-1 sm:px-10 pb-5">
        <div className="relative px-6 pt-5 pb-5 mt-8 ring-1 ring-gray-900/5 sm:px-10">
          <div className="mx-auto max-w-full sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl">
            <div className="mx-auto grid w-full divide-y ">
              {data.map((data) => (
                <div key={data.id} className="py-5">
                  <details className="group">
                    <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span>{data.title}</span>
                      <span className="transition group-open:rotate-180">
                        <Icon name="arrowDown" className="brand-color" />
                      </span>
                    </summary>
                    <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                      {data.desc}
                    </p>
                  </details>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqt;
