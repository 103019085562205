import React from "react";

const Information = () => {
  return (
    <>
      <div className="">
        <section
          className="flex flex-col "
          style={{ backgroundColor: "#F2F7FF" }}
        >
          <p className="font-semibold text-2xl md:text-3xl text-center mt-20">
            Proudly Showcasing Our Accomplishments
          </p>
          <p className="text-center text-md leading-6 text-neutral-500 mx-10">
            At the core of our values lies the dedication to delivering
            top-notch service and fulfilling our promises. Our success is not
            just measured by words but also by tangible results and impressive
            statistics.
          </p>

          <div className="mt-10 mb-20 grid grid-cols-2 lg:grid-cols-4 gap-y-5 lg:gap-y-0 gap-x-5 place-items-center w-full mx-auto max-w-7xl px-5">
            <div className="flex flex-col justify-center items-center px-4 justify-self-center">
              <div className="">
                <p className="font-bold text-xl sm:text-2xl lg:text-2xl leading-9 text-primary">
                  02+
                </p>
              </div>
              <p className="font-medium text-neutral-400 text-md leading-6 text-center">
                Year of customer focus service
              </p>
            </div>
            <div className="flex flex-col justify-center items-center px-4 justify-self-center">
              <div className="">
                <p className="font-bold text-xl sm:text-2xl lg:text-2xl leading-9 text-primary">
                  05+
                </p>
              </div>

              <p className="font-medium text-neutral-400 text-md leading-6 text-center">
                Safe & Secured Clients
              </p>
            </div>
            <div className="flex flex-col justify-center items-center px-4 justify-self-center">
              <div className="">
                <p className="font-bold text-xl sm:text-2xl lg:text-2xl leading-9 text-primary">
                  04+
                </p>
              </div>

              <p className="font-medium text-neutral-400 text-md leading-6 text-center">
                Deliverd Project
              </p>
            </div>
            <div className="flex flex-col justify-center items-center px-4 justify-self-center">
              <div className="">
                <p className="font-bold text-xl sm:text-2xl lg:text-2xl leading-9 text-primary">
                  10+
                </p>
              </div>

              <p className="font-medium text-neutral-400 text-md leading-6 text-center">
                Happy Clients
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Information;
