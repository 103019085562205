import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Icon from "./jsons/Icon";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Services", href: "/service", current: false },
  { name: "Career", href: "/career", current: false },
  { name: "About", href: "/about", current: false },
  { name: "Contact Us", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  return (
    <>
      <div class=" top-0 z-50">
        <div class="bg-brand-color">
          <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div class="flex flex-col items-center justify-between lg:flex-row lg:justify-center">
              <div class="flex flex-1 items-center lg:mr-3 lg:flex-none">
                <p class="ml-3 text-center font-medium text-white flex justify-between gap-1">
                  <p>
                    <Icon name="speaker" size={22} />
                  </p>
                  <p>
                    To celebrate
                    <span class="font-semibold">
                      {" "}
                      Azadi Ka Amrit Mahotsav
                    </span>{" "}
                    Get <span class="font-black">10% off </span>
                  </p>
                </p>
              </div>
              <div class="mt-2 w-full flex-shrink-0 lg:mt-0 lg:w-auto">
                <Link
                  class="flex items-center justify-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium brand-color shadow-sm hover:bg-brand-color"
                  to="/contact"
                >
                  Contact now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Disclosure as="nav" className="text-black p-2" style={{ zIndex: 1000 }}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <Link to="/">
                    <div className="cursor-pointer">
                      <p className="text-xl font-bold leading-none tracking-tighter font-serif">
                        <span className="brand-color">C</span>ode
                        <span className="brand-color">A</span>rtha
                      </p>
                      <p
                        className="text-neutral-400 text-center leading-tight"
                        style={{ fontSize: "8px" }}
                      >
                        Elegant & Unique Services
                      </p>
                    </div>
                  </Link>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "brand-color"
                              : "text-black-300 hover-brand-color",
                            "rounded-md px-3 py-2 text-md font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative rounded-full bg-neutral-300 p-1 text-black "
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Contact Us</span>
                  </button>

                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full text-sm ">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <Link
                          to="/contact"
                          class="group relative flex justify-center px-4 py-3 rounded-full border-1 bg-brand-color font-bold text-white transition duration-800 ease-in-out"
                        >
                          <div class="flex justify-center items-center">
                            <Icon
                              name="message"
                              className=""
                              size={18}
                              color="white"
                            />
                          </div>
                        </Link>
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-neutral-300 brand-color"
                        : "text-black hover-brand-color",
                      "block rounded-md px-3 py-2 text-sm font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
