import React from "react";
import about1 from "../image/about1.png";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div className="">
        <section className="overflow-hidden pt-16 pb-16">
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="grid items-center grid-cols-1 md:grid-cols-2">
              <div>
                <h2 className="text-base font-semibold leading-7 brand-color">
                  About Us
                </h2>
                <h2 className="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl">
                  Reinventing careers
                </h2>
                <p className="max-w-lg mt-3 text-xl leading-relaxed text-neutral-400 dark:text-gray-300 md:mt-8">
                  We aim to boost a culture of self-mastery with endless
                  possibilities. By exploring five professional development
                  dimensions, we empower Globers to be the owners of their
                  careers, choosing their path, and nurturing their skill sets.
                </p>
                <p className="mt-4 text-md text-neutral-400 dark:text-neutral-400 md:mt-8">
                  <span className="relative inline-block">
                    <span className="absolute inline-block w-full bottom-0.5 h-2 bg-brand-color dark:bg-neutral-400"></span>
                    <span className="relative"> Have a question? </span>
                  </span>
                  <br className="block sm:hidden" />
                  Ask me on{" "}
                  <Link
                    to="/contact"
                    className="transition-all duration-200 brand-color hover-brand-colo hover:underline"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>

              <div className="relative">
                <img
                  className="absolute inset-x-0 bottom-0 -mb-48 -translate-x-1/2 left-1/2"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/blob-shape.svg"
                  alt=""
                />

                <img
                  className="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110"
                  src={about1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
