import React from "react";
import { Link } from "react-router-dom";
import Icon from "./jsons/Icon";

export default function Footer() {
  return (
    <>
      <div className="bg-brand-color pt-9">
        <div className="mx-auto w-full max-w-[1166px] px-4 xl:px-0">
          <div className="flex flex-col justify-between sm:px-[18px] md:flex-row md:px-10">
            <div className="md:w-[316px]">
              <p className="text-[18px] font-medium text-white">
                <h1 className="text-white">
                  <Link to="/">CodeArtha Technologies</Link>
                </h1>
              </p>
              <p className="mt-[18px] text-[15px] font-normal text-white/[80%]">
                CodeArtha delivers essential services, including web and mobile
                app development, to both startups and established enterprises.
                Our mission is to foster serenity through integrity and
                innovation, providing clients with tailored solutions for their
                digital needs. Join us on the path to success, where integrity
                drives innovation.
              </p>
              <div className="mt-[18px] flex gap-4 pb-3">
                <Link className="hover:scale-110" target="_blank" to="/">
                  <Icon name="linkedin2" size={15} color="white" />
                </Link>
                <Link className="hover:scale-110" target="_blank" to="/">
                  <Icon name="facebook2" size={15} color="white" />
                </Link>
                <Link className="hover:scale-110" target="_blank" to="/">
                  <Icon name="twitter" size={15} color="white" />
                </Link>
                <Link className="hover:scale-110" target="_blank" to="/">
                  <Icon name="instagram" size={15} color="white" />
                </Link>
                <Link
                  className="hover:scale-110"
                  target="_blank"
                  to="https://www.youtube.com/"
                >
                  <Icon name="youtube" size={15} color="white" />
                </Link>
              </div>
            </div>
            <div className="md:w-[316px]">
              <div className=" flex">
                <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                  <Icon name="phone" size={24} color="white" />
                </div>
                <div className="ml-[18px]">
                  <Link
                    to="tel:+916396978565"
                    className="font-Inter text-[14px] font-medium text-white"
                  >
                    +91 6396978565, +91 8279611393
                  </Link>
                  <p className="font-Inter text-[12px] font-medium text-white">
                    Support Number
                  </p>
                </div>
              </div>
              <div className="mt-[23px] flex">
                <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                  <Icon name="mail" size={24} color="white" />
                </div>
                <div className="ml-[18px]">
                  <Link
                    to="mailto:teamcodeartha@gmail.com"
                    className="font-Inter text-[14px] font-medium text-[#fff]"
                  >
                    teamcodeartha@gmail.com
                  </Link>
                  <p className="font-Inter text-[12px] font-medium text-[#fff]">
                    Support Email
                  </p>
                </div>
              </div>
              <div className="mt-[23px] flex">
                <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                  <Icon name="location" size={24} color="white" />
                </div>
                <div className="ml-[18px]">
                  <Link
                    to="/"
                    className="font-Inter text-[14px] font-medium text-[#fff]"
                  >
                    A-133 Sector 63 Noida, India
                  </Link>
                  <p className="font-Inter text-[12px] font-medium text-white">
                    Address
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex w-full flex-col justify-between text-white sm:flex-row md:mt-0 md:max-w-[341px]">
              <div className="">
                <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal">
                  Links
                </p>
                <ul>
                  <li className="mt-[15px]">
                    <Link
                      className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                      to="/our-tutors"
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                      to="/our-tutors"
                    >
                      About
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                      to="/become-a-tutor"
                    >
                      Career
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                      to="/become-a-tutor"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-6 flex flex-col gap-4 sm:mt-0">
                <p className="text-deutziawhite font-inter text-[18px] font-medium">
                  Services
                </p>
                <div className="flex gap-4 sm:flex-col">
                  <ul>
                    <li className="">
                      <Link
                        className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to="/plans-and-pricing"
                      >
                        Web Development
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to="/plans-and-pricing"
                      >
                        App Development
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to="/plans-and-pricing"
                      >
                        Cloud Operation
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to="/plans-and-pricing"
                      >
                        UI/UX Desgine
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="text-deutziawhite hover:text-deutziawhite/80 font-inter text-[15px] font-normal hover:font-semibold"
                        to="/plans-and-pricing"
                      >
                        Digital Marketing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-[30px] text-white" />
          <div className="flex items-center justify-center pb-8 pt-[9px] md:py-8">
            <p className="text-[10px] font-normal text-white md:text-[12px]">
              © Copyright 2024, All Rights Reserved by{" "}
              <Link to="/" className="hover:underline">
                CodeArtha Technologies Pvt. Ltd
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
