import React from "react";
import Icon from "../jsons/Icon";
import { Link } from "react-router-dom";

const Services = () => {
  const data = [
    {
      id: 1,
      title: "Web Development",
      desc1:
        "We specialize in delivering cutting-edge web development solutions tailored to meet the unique needs of our clients. Our dedicated team of experienced developers works tirelessly to create stunning websites that not only captivate audiences but also drive results. Whether you're looking for a custom-designed website, an e-commerce platform, or a robust content management system, we have the expertise to bring your vision to life. With a proven track record of success and a portfolio of satisfied clients, we take pride in our ability to deliver exceptional results on every project.",
      desc2:
        "We're not just building websites; we're building partnerships. Contact us today to discuss how we can help you achieve your online goals.",
      style: "bg-gray-50 ",
      features: [
        {
          id: 1,
          img: (
            <>
              <Icon name="web1" size={40} />
            </>
          ),
          title: "Responsive Design",
        },
        {
          id: 2,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "User Experience",
        },
        {
          id: 3,
          img: (
            <>
              <Icon name="web3" size={40} />
            </>
          ),
          title: "Scalability",
        },
        {
          id: 4,
          img: (
            <>
              <Icon name="security" size={40} />
            </>
          ),
          title: "Security",
        },
        {
          id: 5,
          img: (
            <>
              <Icon name="browser" size={40} />
            </>
          ),
          title: "Cross-Browser compatibility",
        },
        {
          id: 6,
          img: (
            <>
              <Icon name="seo" size={40} />
            </>
          ),
          title: "Search Engine Optimization (SEO)",
        },
      ],
    },
    {
      id: 2,
      title: "App Development",
      desc1:
        "Mobile applications have become an integral part of modern life, offering unparalleled convenience and functionality at our fingertips. As the app development market continues to expand at a rapid pace, businesses must leverage this growing trend to stay competitive.",
      desc2:
        "We recognize the importance of mobile apps in today's digital landscape. Our dedicated team of app developers stays ahead of the curve, harnessing the latest technologies and trends to create innovative and user-friendly applications. With a keen understanding of market dynamics and consumer behavior, we craft mobile solutions that not only meet the needs of our clients but also resonate with their target audience. As the demand for mobile apps continues to soar, partnering with CodeArtha ensures that your business remains at the forefront of this ever-evolving industry. Contact us today to explore the endless possibilities of mobile app development and propel your business to new heights.",

      features: [
        {
          id: 1,
          img: (
            <>
              <Icon name="web1" size={40} />
            </>
          ),
          title: "Multi-platform Compatibility",
        },
        {
          id: 2,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "Analytics",
        },
        {
          id: 3,
          img: (
            <>
              <Icon name="support" size={40} />
            </>
          ),
          title: "Feedback & Support",
        },
        {
          id: 4,
          img: (
            <>
              <Icon name="security" size={40} />
            </>
          ),
          title: "Security",
        },
        {
          id: 5,
          img: (
            <>
              <Icon name="userCheck" size={40} />
            </>
          ),
          title: "User Authentication",
        },
        {
          id: 6,
          img: (
            <>
              <Icon name="seo" size={40} />
            </>
          ),
          title: "Scalability & Flexibility",
        },
      ],
    },
    {
      id: 3,
      title: "UI/UX Design",
      desc1:
        "In today's digital age, user interface (UI) and user experience (UX) design play a crucial role in the success of any digital product or service. UI/UX design focuses on creating intuitive, visually appealing interfaces that enhance the overall user experience.",
      desc2:
        "we understand the significance of UI/UX design in driving user engagement, satisfaction, and retention. Our team of talented designers combines creativity with usability principles to craft interfaces that not only look great but also function seamlessly across various devices and platforms. With user-centric design at the forefront of our process, we strive to create experiences that delight users and keep them coming back for more. In an increasingly competitive digital landscape, investing in UI/UX design is more important than ever. Partner with CodeArtha to elevate your digital presence and deliver exceptional experiences that set you apart from the competition.",
      style: "bg-gray-50 ",
      features: [
        {
          id: 1,
          img: (
            <>
              <Icon name="web1" size={40} />
            </>
          ),
          title: "Intuitive Navigation",
        },
        {
          id: 2,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "Clear Visual Hierarchy",
        },
        {
          id: 3,
          img: (
            <>
              <Icon name="web3" size={40} />
            </>
          ),
          title: "Consistent Design Language",
        },
        {
          id: 4,
          img: (
            <>
              <Icon name="security" size={40} />
            </>
          ),
          title: "Accessibility",
        },
        {
          id: 5,
          img: (
            <>
              <Icon name="multipleuser" size={40} />
            </>
          ),
          title: "Gestural Interaction",
        },
        {
          id: 6,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "Responsive Layout",
        },
      ],
    },
    {
      id: 4,
      title: "Cloud Operation",
      desc1:
        "we specialize in delivering comprehensive cloud operations and DevOps solutions tailored to meet the unique needs of our clients. Our experienced team of cloud engineers and DevOps professionals leverages industry best practices and cutting-edge tools to streamline development processes, automate infrastructure management, and optimize deployment workflows. Whether you're migrating to the cloud, managing a hybrid infrastructure, or implementing continuous integration and delivery pipelines,",
      desc2:
        "we have the expertise to help you achieve your goals. By partnering with CodeArtha , you can unlock the full potential of cloud technologies and DevOps practices, enabling faster time-to-market, improved scalability, and enhanced resilience for your applications and services. Get in touch with us today to embark on your cloud transformation journey and revolutionize the way you do business",
      features: [
        {
          id: 1,
          img: (
            <>
              <Icon name="web1" size={40} />
            </>
          ),
          title: "Pipeline Visualization",
        },
        {
          id: 2,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "Configuration Management",
        },
        {
          id: 3,
          img: (
            <>
              <Icon name="web3" size={40} />
            </>
          ),
          title: "Monitoring & Alerting",
        },
        {
          id: 4,
          img: (
            <>
              <Icon name="security" size={40} />
            </>
          ),
          title: "Deployment Orchestration",
        },
        {
          id: 5,
          img: (
            <>
              <Icon name="browser" size={40} />
            </>
          ),
          title: "Security & Compliance",
        },
        {
          id: 6,
          img: (
            <>
              <Icon name="seo" size={40} />
            </>
          ),
          title: "Analytics & Insights",
        },
      ],
    },
    {
      id: 5,
      title: "E-Commerce (Shopify partners)",
      desc1:
        "In today's digital marketplace, establishing a strong online presence is essential for businesses to thrive. As a Shopify e-commerce service provider, CodeArtha is dedicated to helping businesses of all sizes succeed in the world of online retail. Our team specializes in leveraging the power of Shopify's robust platform to create customized e-commerce solutions that drive sales, engage customers, and build brand loyalty. From setting up your online store and designing eye-catching storefronts to optimizing product listings and implementing secure payment gateways, we handle every aspect of your e-commerce journey with precision and expertise. With Shopify's scalable infrastructure and our industry-leading knowledge.",
      desc2:
        "You can rest assured that your online store is in good hands. Partner with CodeArtha to unlock the full potential of Shopify and take your e-commerce business to new heights. Contact us today to discuss your e-commerce goals and let's turn your vision into reality.",
      style: "bg-gray-50 ",
      features: [
        {
          id: 1,
          img: (
            <>
              <Icon name="laptop" size={40} />
            </>
          ),
          title: "Customizable Storefront",
        },
        {
          id: 2,
          img: (
            <>
              <Icon name="web2" size={40} />
            </>
          ),
          title: "Analytics & Reporting",
        },
        {
          id: 3,
          img: (
            <>
              <Icon name="support" size={40} />
            </>
          ),
          title: "Customer Support",
        },
        {
          id: 4,
          img: (
            <>
              <Icon name="security" size={40} />
            </>
          ),
          title: "Security",
        },
        {
          id: 5,
          img: (
            <>
              <Icon name="code" size={40} />
            </>
          ),
          title: "Responsive Design",
        },
        {
          id: 6,
          img: (
            <>
              <Icon name="seo" size={40} />
            </>
          ),
          title: "SEO & Optimization",
        },
      ],
    },
  ];
  return (
    <div>
      <div className="">
        <div class="bg-white">
          <div class="mx-auto  sm:px-6 lg:px-8">
            <div class="relative isolate overflow-hidden px-6 py-24 text-center sm:rounded-3xl sm:px-16">
              <h2 class="font-nudge-extrabold mx-auto max-w-2xl text-2xl font-bold tracking-wide sm:text-3xl">
                Tech Solutions: your complete IT partner
              </h2>
              <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                At TechSolutions, we provide comprehensive IT services
                customized to suit your specific requirements. Whether it's
                network management, cybersecurity, or software development, we
                serve as your reliable ally in maneuvering through the digital
                realm
              </p>
              <div class="isolate mt-8 flex items-center justify-center -space-x-2 overflow-hidden">
                <span class="!ml-2 font-bold italic brand-color">
                  Connect with Us Today!
                </span>
              </div>
              <div class="mt-3 flex items-center justify-center gap-x-6">
                <Link
                  to="/"
                  type="button"
                  class="text-md relative inline-flex items-center gap-x-2 rounded-lg bg-brand-color px-6 py-4 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Connect Now
                  <Icon name="downArrowKey" size={20} />
                </Link>
              </div>
              <svg
                viewBox="0 0 1024 1024"
                class="absolute left-1/2 top-1/2 -z-10 h-[72rem] w-[72rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                aria-hidden="true"
              >
                <circle
                  cx="512"
                  cy="512"
                  r="512"
                  fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                  fill-opacity="0.7"
                ></circle>
                <defs>
                  <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                    <stop stop-color="#60A5FA"></stop>
                    <stop offset="1" stop-color="#60A5FA"></stop>
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        {data.map((data) => (
          <div class={` p-4 pb-10 ${data?.style}`}>
            <div class="max-w-7xl mx-auto md:px-12 xl:px-6">
              <div class="mt-12 text-white">
                <h2 class="my-4 text-2xl font-bold text-black md:text-4xl">
                  {data.title}
                </h2>
                <p class="text-neutral-700 pb-3">{data.desc1}</p>

                <p className="text-neutral-700">{data.desc2}</p>
              </div>
              <div class="container relative z-40 mx-auto mt-12">
                <div class="grid grid-cols-2 sm:grid-cols-6">
                  {data.features.map((feature) => (
                    <div key={feature.id} class="py-5 text-center border">
                      <div>
                        <p className="flex flex-wrap justify-center brand-color">
                          {feature.img}
                        </p>
                        <p class="m-1 text-sm font-medium capitalize font-body text-black lg:text-lg md:text-base">
                          {feature.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
